import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { customerGroupsByUser, vatZonesByUser } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import type { CustomerGroups, VatZones } from './types';
import { EconomicTransferCoC } from '../transfer-coc';
import { CustomerContactPriorities } from './customerContact';
import { SelectedAttentionContact } from './attentionCustomerContact';
import { EconomicUploadEmail } from './uploadEmail';

export const CustomerForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, handleSelectChange, setEconomicUserDefaultData }) => {
  const [customerGroups, setCustomerGroups] = React.useState<CustomerGroups[]>([]);
  const [vatZones, setVatZones] = React.useState<VatZones[]>([]);

  React.useEffect(() => {
    const getCustomerGroups = async () => {
      const customerGroupsResponse = await customerGroupsByUser();
      if (customerGroupsResponse) {
        setCustomerGroups(customerGroupsResponse.data);
      }
    };

    const getVatZones = async () => {
      const vatZonesResponse = await vatZonesByUser();
      if (vatZonesResponse) {
        setVatZones(vatZonesResponse.data);
      }
    };

    getCustomerGroups();
    getVatZones();
  }, []);

  const handleCustomerMatchingChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        customer_match: [].slice.call(event.target.selectedOptions).map((item: HTMLOptionElement) => `${item.value}`),
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCustomerMatching">
        <Form.Label column sm={6}>
          Specify criteria for customer matching
        </Form.Label>
        <Col sm={6}>
          <Form.Select multiple aria-label="Floating label select" value={economicUserDefaultData.customer_match} onChange={handleCustomerMatchingChange}>
            <option value="id" data-key={'customer_match'}>
              ID
            </option>
            <option value="databaseNumber" data-key={'customer_match'}>
              Database Number
            </option>
            <option value="vat" data-key={'customer_match'}>
              VAT identification number
            </option>
            <option value="email" data-key={'customer_match'}>
              Email
            </option>
            <option value="email2" data-key={'customer_match'}>
              Email 2
            </option>
            <option value="phone" data-key={'customer_match'}>
              Phone
            </option>
            <option value="phone2" data-key={'customer_match'}>
              Phone 2
            </option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCustomerGroup">
        <Form.Label column sm={6}>
          Specify default customer group
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectCustomerGroup" label="Select customer group">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.customer_groups?.customerGroupNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'customer_groups'}>
                Open this select menu
              </option>
              {customerGroups.length !== 0 &&
                customerGroups.map((customerGroup) => (
                  <option
                    key={`customerGroup-${customerGroup.customerGroupNumber}`}
                    value={customerGroup.customerGroupNumber}
                    data-self={customerGroup.self}
                    data-key={'customer_groups'}
                    data-numberkey={'customerGroupNumber'}
                    data-selfkey={'self'}
                  >
                    {customerGroup.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalVatZone">
        <Form.Label column sm={6}>
          Specify default VAT zone
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectVatZone" label="Select vat zone">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.vat_zone?.vatZoneNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'vat_zone'}>
                Open this select menu
              </option>
              {vatZones.length !== 0 &&
                vatZones.map((vatZone) => (
                  <option
                    key={`vatZone-${vatZone.vatZoneNumber}`}
                    value={vatZone.vatZoneNumber}
                    data-self={vatZone.self}
                    data-key={'vat_zone'}
                    data-numberkey={'vatZoneNumber'}
                    data-selfkey={'self'}
                  >
                    {vatZone.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>

      <EconomicTransferCoC economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <CustomerContactPriorities economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <SelectedAttentionContact economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <EconomicUploadEmail economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
    </>
  );
};
