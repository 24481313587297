import React from 'react';
import { ContentSectionProps } from './types';
import { Container, Row } from 'react-bootstrap';

export const ContentSection: React.FC<ContentSectionProps> = ({ title, content, anchor }) => {
  return (
    <Container className="content-section-container" id={anchor}>
      <Row>
        <h3 className="content-section-container-header">{title}</h3>
      </Row>
      <Row>
        <p>
          {content.map((item, index) => {
            if (typeof item === 'string') {
              return item;
            } else if ('link' in item) {
              return (
                <a target="_blank" rel="noopener noreferrer" key={index} href={item.link}>
                  {item.text}
                </a>
              );
            } else if ('list' in item) {
              return (
                <Row key={index} className="ms-3 mt-3">
                  <ul>
                    {item.list.map((listItem, listItemIndex) => (
                      <li key={listItemIndex}>{listItem}</li>
                    ))}
                  </ul>
                </Row>
              );
            } else if ('bold' in item) {
              return <strong key={index}>{item.text}</strong>;
            } else if ('imageSrc' in item) {
              return (
                <Row key={index}>
                  <img src={item.imageSrc} className="content-section-container-image" />
                </Row>
              );
            }
          })}
        </p>
      </Row>
    </Container>
  );
};
