import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomerForm } from './customer';
import { InvoiceForm } from './invoice';
import { CreditNoteForm } from './credit-note';
import { ProductForm } from './product';
import { EmployeeForm } from './employee';

import economicLogo from '@public/img/economic-logo.png';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { PaymentForm } from './payment';

export const EconomicForm: React.FC<EconomicSettingModuleProps> = ({
  economicUserDefaultData,
  setEconomicUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <div className="settings-heading">
            <img src={economicLogo} alt="E-conomic logo" height="65" loading="lazy" />
          </div>
          <h4 className="mb-4">Customer</h4>
          <p>Set how customers are created and updated in your accounts. Customer data is received with the order transfer.</p>
          <CustomerForm
            economicUserDefaultData={economicUserDefaultData}
            setEconomicUserDefaultData={setEconomicUserDefaultData}
            handleSelectChange={handleSelectChange}
          />
        </Col>
      </Row>

      <hr />

      <Row className="mt-4 mb-4">
        <Col>
          <h4 className="mb-4">Invoice</h4>
          <p>Set how invoices should be created in your accounts.</p>
          <InvoiceForm
            economicUserDefaultData={economicUserDefaultData}
            setEconomicUserDefaultData={setEconomicUserDefaultData}
            handleSelectChange={handleSelectChange}
            currentMonthPaymentTermsError={currentMonthPaymentTermsError}
            setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
          />
        </Col>
      </Row>

      <hr />

      <Row className="mt-4 mb-4">
        <Col>
          <h4 className="mb-4">Payment</h4>
          <p>Set how payments should be handled in your accounts.</p>
          <PaymentForm economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
        </Col>
      </Row>

      <hr />

      <Row className="mt-4 mb-4">
        <Col>
          <h4 className="mb-4">Credit note</h4>
          <p>Set how the credit note should be created in your accounts.</p>
          <CreditNoteForm economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col className="mt-4 mb-4">
          <h4 className="mb-4">Products</h4>
          <p>Set how products are created and updated in E-conomic.</p>
          <ProductForm
            economicUserDefaultData={economicUserDefaultData}
            setEconomicUserDefaultData={setEconomicUserDefaultData}
            handleSelectChange={handleSelectChange}
          />
        </Col>
      </Row>

      <hr />

      <Row className="mt-4 mb-4">
        <Col>
          <h4 className="mb-4">Employee</h4>
          <p>Set how employee are created and updated in E-conomic.</p>
          <EmployeeForm economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
        </Col>
      </Row>
    </>
  );
};
