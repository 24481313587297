import { ContentSection } from '@src/components/content-section';
import { TableOfContents } from '@src/components/table-of-contents';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

//Images
import apiKey from '@public/img/setup/rentman-guide-apikey.jpg';
import webhook from '@public/img/setup/rentman-guide.jpg';
import insertWebhook from '@public/img/setup/rentman-guide-webhook.jpg';
import economicKeys from '@public/img/setup/rentman-economic-keys.jpg';
import develeoperAgreement from '@public/img/setup/Sign up for developer agreement.png';
import emailActivation from '@public/img/setup/email-activate-account.png';
import newApp from '@public/img/setup/create-new-app.png';
import appSecretToken from '@public/img/setup/copy-app-secret-token.png';
import installationUrl from '@public/img/setup/installation-url.png';
import insertInstallationUrl from '@public/img/setup/insert-installationurl.png';
import grantToken from '@public/img/setup/grant-token.png';
import economicWebhookData from '@public/img/setup/economic-webhook-data.png';
import economicSettings from '@public/img/setup/economic-settings.png';
import webhooksCategory from '@public/img/setup/webhooks-section.png';
import newWebhookButton from '@public/img/setup/new-webhook-button.png';
import createWebhookForm from '@public/img/setup/create-webhook-form.png';
import otherFieldsWebhook from '@public/img/setup/other-fields-webhook.png';
import saveButton from '@public/img/setup/save-button.png';

export const SetupPage: React.FC = () => {
  const contents = [
    {
      title: '1. API key integration',
      anchor: 'api-integration',
      content: [
        'To integrate your API key from Rentman, navigate to ',
        { bold: true, text: 'Integration > Connections ' },
        'or ',
        { link: '../connection-config', text: 'press this link' },
        '\n You can find a guide on how to generate an ',
        { link: 'https://support.rentman.io/hc/en-us/articles/360013767839-The-Rentman-API', text: 'API key from rentman here.' },
        { imageSrc: apiKey },
      ],
    },
    {
      title: '2. Activate webhooks',
      anchor: 'activate-webhooks',
      content: ['To activate webhooks in Rentman, you have to contact the ', { link: 'https://support.rentman.io/hc/en-us', text: 'Rentman Support Center' }],
    },
    {
      title: '3. Adjust webhook settings',
      anchor: 'adjust-webhook-settings',
      content: [
        'Adjust webhook settings by copying this URL: ',
        { link: 'https://rentman-economic.ew.r.appspot.com/rentman/hook', text: 'https://rentman-economic.ew.r.appspot.com/rentman/hook ' },
        'and then pressing save.',
        { imageSrc: webhook },
      ],
    },
    {
      title: '4. Insert webhook token',
      anchor: 'insert-webhook-token',
      content: [
        'Copy your webhook token and insert it into the Rentman form field under ',
        { bold: true, text: 'Integration > Connections \n' },
        { imageSrc: insertWebhook },
      ],
    },
    {
      title: '5. E-Conomic developer agreement',
      anchor: 'developer-agreement',
      content: [
        { bold: true, text: '1.' },
        ' Create an ',
        { link: 'https://www.e-conomic.com/developer', text: 'E-conomic developer agreement here' },
        ' by entering your information in the form on their website.',
        { imageSrc: develeoperAgreement },
        { bold: true, text: '2.' },
        ' Navigate to your e-mail and press the ',
        { bold: true, text: 'Activate and create password ' },
        'link, create a password and then login to your developer account.',
        { imageSrc: emailActivation },
        { bold: true, text: '3.' },
        ' Create a new app by entering a name, selecting the roles, and specifying the required modules.',
        { imageSrc: newApp },
        { bold: true, text: '4.' },
        ' After creating your project, you will be presented with your ',
        { bold: true, text: 'AppSecretToken ' },
        'Copy this token and insert it into the E-Conomic integration form in ',
        { bold: true, text: 'Integration > Connections ' },
        'here in the Rentman - e-conomic integration, then press the ',
        { bold: true, text: 'Continue to Tokens ' },
        'button.',
        { imageSrc: appSecretToken },
        { bold: true, text: '5.' },
        ' After continuing you will be presented with your installationURL. Copy this URL and save it somewhere. After this you can press the ',
        { bold: true, text: 'Save and Close ' },
        'button.',
        { imageSrc: installationUrl },
        { bold: true, text: '6.' },
        ' Copy your installationURL and log in to your e-conomic account with accounting data, then paste the installationURL into the browser URL, once navigated to the installationURL you can press the ',
        { bold: true, text: 'Tilføj app ' },
        'button.',
        { imageSrc: insertInstallationUrl },
        { bold: true, text: '7.' },
        'You have now been presented with your ',
        { bold: true, text: 'Grant Token ' },
        'copy this token and paste it into the e-conomic form in the Rentman - e-conomic integration at ',
        { bold: true, text: 'Integration > Connections' },
        { imageSrc: grantToken },
        'You now have your Secret and Grant tokens',
      ],
    },
    {
      title: '6. App Secret and Grant token',
      anchor: 'app-secret-and-grant',
      content: [
        'Copy your App Secret and your Grant token, and navigate to ',
        { bold: true, text: 'Integration > Connections' },
        '.\n Insert them into the E-conomic connection form.',
        { imageSrc: economicKeys },
      ],
    },
    {
      title: '7. Payment sync setup from E-conomic to Rentman',
      anchor: 'payment-sync-economic',
      content: [
        { bold: true, text: '1. ' },
        'Once you have set up your Secret Token and Grant Token you will gain access to your ',
        { bold: true, text: 'Economic Webhook Data ' },
        'in ',
        { bold: true, text: 'Integration > Connections' },
        '.\n Press the copy button to copy the webhook data.',
        { imageSrc: economicWebhookData },
        { bold: true, text: '2. ' },
        'Head to your e-conomic dashboard, press the settings button and go to ',
        { bold: true, text: 'Alle Indstillinger ' },
        'in the top-right corner',
        { imageSrc: economicSettings },
        { bold: true, text: '3. ' },
        'Scroll down in the menu on the left hand side, until you see ',
        { bold: true, text: 'Udvidelser ' },
        'then select ',
        { bold: true, text: 'Webhooks' },
        { imageSrc: webhooksCategory },
        { bold: true, text: '4. ' },
        'Press the ',
        { bold: true, text: 'Nyt webhook ' },
        'button',
        { imageSrc: newWebhookButton },
        { bold: true, text: '5. ' },
        'You will now be prompted to create a new webhook. Insert your ',
        { bold: true, text: 'Economic Webhook Data ' },
        'into the ',
        { bold: true, text: 'Sendte data ' },
        'field.',
        { imageSrc: createWebhookForm },
        { bold: true, text: '6. ' },
        'The rest of the fields on the left-hand side have to be filled as follows: ',
        { list: ['Type: Kassekladde bogført', 'Navn: Payments Webhook', 'URL: https://rentman-economic.ew.r.appspot.com/economic/hook'] },
        { imageSrc: otherFieldsWebhook },
        { bold: true, text: '7. ' },
        'Lastly, press the ',
        { bold: true, text: 'Gem ' },
        'button and you are ready to start syncing payments from E-conomic to Rentman',
        { imageSrc: saveButton },
      ],
    },
  ];

  return (
    <Card className="mt-4">
      <Card.Header>Setup guide</Card.Header>
      <Card.Body>
        <Row>
          <Col className="toc-col">
            <div className="toc-col-container">
              <TableOfContents sections={contents}></TableOfContents>
            </div>
          </Col>
          <Col lg={9}>
            {contents.map((content) => (
              <div className="content-section" key={content.anchor} id={content.anchor}>
                <ContentSection {...content} />
              </div>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
