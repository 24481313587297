import React from 'react';

import { EconomicOtherReference } from './other_reference';
import { EconomicLayout } from './layout';
import { EconomicPaymentTerms } from './payment_terms';
import { EconomicDefaultCurrency } from './default_currency';
import { EconomicRoundingDifference } from './rounding_difference';
import { EconomicDeparmentSync } from './deparment_sync';
import { EconomicExternalId } from './external_id';
import { EconomicReference } from './reference';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { EconomicQuotationContractEqual } from './quotation_contract_equal';

export const InvoiceForm: React.FC<EconomicSettingModuleProps> = ({
  economicUserDefaultData,
  setEconomicUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  const referenceOptions = (plament: string) => {
    return (
      <>
        <option value="" data-key={`${plament}-none`}>
          None
        </option>
        <option value="projectReference" data-key={`${plament}-project-reference`}>
          Project Reference
        </option>
        <option value="projectName" data-key={`${plament}-project-name`}>
          Project Name
        </option>
        <option value="invoiceNumber" data-key={`${plament}-invoice`}>
          Invoice Number
        </option>
        <option value="projectNumber" data-key={`${plament}-project`}>
          Project Number
        </option>
        <option value="databaseNumber" data-key={`${plament}-database-number`}>
          Database Number
        </option>
        <option value="accountingNumber" data-key={`${plament}-accounting-number`}>
          Accounting Number
        </option>
        <option value="purchaseNumber" data-key={`${plament}-purchase-number`}>
          Purchase Number
        </option>
        <option value="extNameLine" data-key={`${plament}-ext-name-line`}>
          Name Line 2
        </option>
      </>
    );
  };

  return (
    <>
      <EconomicOtherReference
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />

      <EconomicLayout economicUserDefaultData={economicUserDefaultData} handleSelectChange={handleSelectChange} />

      <EconomicPaymentTerms
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        handleSelectChange={handleSelectChange}
        currentMonthPaymentTermsError={currentMonthPaymentTermsError}
        setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
      />

      <EconomicDefaultCurrency economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <EconomicRoundingDifference economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <EconomicDeparmentSync economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />

      <EconomicExternalId
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />

      <EconomicReference
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />
      <EconomicQuotationContractEqual economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
    </>
  );
};
