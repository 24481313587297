import React from 'react';
import { PaymentTabProps } from './types';
import { resyncPayment } from '@src/Api';
import { Button, Spinner, Table } from 'react-bootstrap';

export const PaymentTab: React.FC<PaymentTabProps> = ({ paymentData, loading, setToasts, fetchSyncData }) => {
  const [activePaymentIds, setActivePaymentIds] = React.useState<number[]>([]);

  const handlePaymentResyncClick = async (entryId: number): Promise<void> => {
    setActivePaymentIds((prevActivePaymentIds) => [...prevActivePaymentIds, entryId]);

    const response = await resyncPayment(entryId);

    if (response?.status === 200) {
      const message = response.data.message;
      setToasts((prevToasts) => [...prevToasts, { id: entryId, message }]);
      fetchSyncData();
    }

    setActivePaymentIds((prevActivePaymentIds) => prevActivePaymentIds.filter((id) => id !== entryId));
  };

  const getInvoiceStatus = (status: number): string => {
    let statusText: string;

    if (status === 0) {
      statusText = 'Failed';
    } else if (status === 1 || status === 3) {
      statusText = 'Completed';
    } else {
      statusText = 'Processing';
    }

    return statusText;
  };

  return (
    <Table striped responsive className="enhanced-table">
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Entry ID</th>
          <th>Economic booked invoice ID</th>
          <th>Rentman Invoice ID</th>
          <th>Payment status</th>
          <th>Action</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {paymentData.length !== 0 &&
            paymentData.map((payment) => (
              <tr key={payment.economic_entry_id}>
                <td>{new Date(payment.payment_created).toLocaleString('da-DK')}</td>
                <td>{payment.economic_entry_id}</td>
                <td>{payment.economic_booked_invoice_id}</td>
                <td>{payment.rentman_invoice_id}</td>
                <td>{getInvoiceStatus(payment.payment_status)}</td>
                <td>
                  {activePaymentIds.includes(payment.economic_entry_id) ? (
                    <Spinner className="custom-spinner" animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button onClick={() => handlePaymentResyncClick(payment.economic_entry_id)}>Re-sync</Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </Table>
  );
};
