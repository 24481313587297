import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Button, Spinner } from 'react-bootstrap';
// Types
import { LogsData } from './types';
// Components
import ModalComponent from './modal';
import { PaginationComponent } from '../../components/pagination';
// API
import { allLogs, searchedLogs } from '@src/Api';
import { Search } from '@src/components/search';

export const LogPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [logsData, setLogsData] = useState<LogsData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(20);
  const [resultsPerPage] = useState<number>(20);
  const [selectedLog, setSelectedLog] = useState<LogsData | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleShowData = (log: LogsData) => {
    setSelectedLog(log);
    setShowModal(true);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    fetchLogs();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleClearSearch = () => {
    setClearSearch(true);
  };

  const fetchLogs = async (): Promise<void> => {
    setLoading(true);
    if (clearSearch) {
      setSearchQuery('');
      setSelectedOption('');
      setClearSearch(false);
    }

    let response;
    if (searchQuery || selectedOption) {
      response = await searchedLogs(resultsPerPage, currentPage, searchQuery, selectedOption);
    } else {
      response = await allLogs(resultsPerPage, currentPage);
    }

    if (response) {
      setLogsData(response.data.results);
      setTotalResults(response.data.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchLogs();
    setLoading(false);
  }, [currentPage, resultsPerPage, selectedOption, clearSearch]);

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Log</Card.Header>
            <Card.Body className="custom-card-body">
              <Row className="mt-4 p-2 mx-4">
                <Col sm="auto">
                  <select className="form-select search-input" aria-label="Default select" value={selectedOption} onChange={handleSelectChange}>
                    <option value="">All</option>
                    <option value="invoice">Invoice</option>
                    <option value="credit note">Credit note</option>
                    <option value="Webhook Request">Webhook</option>
                  </select>
                </Col>
                <Search
                  searchQuery={searchQuery}
                  handleSearch={handleSearch}
                  handleInputChange={handleInputChange}
                  handleClearSearch={handleClearSearch}
                  placeholder="Search logs"
                ></Search>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Table className="enhanced-table" striped responsive>
                    <thead>
                      <tr>
                        <th>Timestamp</th>
                        <th>Log ID</th>
                        <th>Invoice ID</th>
                        <th>Type</th>
                        <th>Event</th>
                        <th>Status</th>
                        <th>Data</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={7} className="table-loader">
                            <Spinner className="data-loading-spinner"></Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {logsData.map((log, index) => (
                          <tr key={index}>
                            <td>{new Date(log.logs_created).toLocaleString('da-DK')}</td>
                            <td>{log.logs_id}</td>
                            <td>{log.logs_invoice_id}</td>
                            <td>{log.logs_type}</td>
                            <td>{log.logs_message}</td>
                            <td>{log.logs_status}</td>
                            <td>
                              <Button onClick={() => handleShowData(log)}>Show data</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              </Row>

              <ModalComponent showModal={showModal} handleModal={handleModal} selectedLog={selectedLog} />

              <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                  <PaginationComponent
                    itemsCount={totalResults}
                    itemsPerPage={resultsPerPage}
                    currentPage={currentPage + 1}
                    setCurrentPage={(pageChangeTo) => {
                      setCurrentPage(pageChangeTo - 1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
