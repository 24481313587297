import React from 'react';
import { SearchProps } from './types';
import { Button, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Search: React.FC<SearchProps> = ({ searchQuery, handleSearch, handleInputChange, handleClearSearch, placeholder }) => {
  return (
    <>
      <Col>
        <InputGroup className="search">
          <input
            type="search"
            placeholder={placeholder}
            id="form1"
            className="form-control search-input"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(searchQuery);
              }
            }}
          />
          <Button className="search-cancel" variant="link" onClick={() => handleClearSearch()}>
            <FontAwesomeIcon icon="circle-xmark" />
          </Button>
          <Button onClick={() => handleSearch(searchQuery)}>
            <FontAwesomeIcon icon="magnifying-glass" />
          </Button>
        </InputGroup>
      </Col>
    </>
  );
};
