import React from 'react';
// Styles
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
// Types
import { UsersIntergrationFormProps } from './types';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';

const RentmanForm: React.FC<UsersIntergrationFormProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  validationError,
  handleValidationError,
  handleRentmanValueChanged,
  handleFormSubmit,
  submissionSuccess,
  setSubmissionSuccess,
}) => {
  return (
    <>
      <Card>
        <Card.Header>Rentman connection</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="users_integrations_url">
              <Form.Label>URL</Form.Label>
              <Form.Control type="url" value="https://api.rentman.net" disabled />
            </Form.Group>
            <Form.Group className="mb-3 connection-field" controlId="apiKey">
              <Form.Label>API Token</Form.Label>
              <Form.Control
                name="apikey"
                type="text"
                placeholder="Enter Rentman API token"
                value={submissionSuccess.rentman ? '****************' : config.rentman.users_integrations_api_data.apiKey}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, rentman: false }))}
                className={!validationError['apikey'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['apikey'] ? '' : '* You need to enter your API token'}</p>
            </Form.Group>
            <Form.Group className="mb-3 connection-field" controlId="apiHookKey">
              <Form.Label>Webhook Token</Form.Label>
              <Form.Control
                name="hookKey"
                type="text"
                placeholder="Enter Rentman Webhook Token"
                value={submissionSuccess.rentman ? '****************' : config.rentman.users_integrations_api_data.apiHookKey}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, rentman: false }))}
                className={!validationError['hookKey'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['hookKey'] ? '' : '* You need to enter your Webhook Token'}</p>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading['rentman'] === true ? true : false} onClick={(e) => handleFormSubmit(e, 'rentman')}>
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header>Debug</Card.Header>
        <Card.Body>
          {loading['rentman'] && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}
          {formSubmitted && (
            <>
              Connection status:
              <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['rentman'], undefined, 4)) }} />
              {errorObject.rentman && (
                <>
                  <div>
                    <SyntaxHighlighter language="json" style={atomOneLight}>
                      {JSON.stringify(errorObject.rentman, null, 2)}
                    </SyntaxHighlighter>
                  </div>
                  <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.rentman, null, 2)} buttonText="Copy" successText="Copied!" />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RentmanForm;
