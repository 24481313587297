import { Card } from 'react-bootstrap';
import { UsersIntergrationFormProps } from './types';
import React from 'react';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import CopyToClipboardButton from '@src/components/copy-clipboard';

const EconomicUserToken: React.FC<Partial<UsersIntergrationFormProps>> = ({ config }) => {
  const dataObject = {
    economicUserToken: `${config?.economic.users_integrations_api_data.economicUserToken}`,
    type: 'dayBookBooked',
    dayBookNumber: '[DAYBOOKNO]',
    fromSerialNumber: '[FROMSERIALNO]',
    toSerialNumber: '[TOSERIALNO]',
  };

  const jsonString = JSON.stringify(dataObject, null, 2);

  const highlightedEconomicData = syntaxHighlight(jsonString);

  return (
    <Card className="mt-4">
      <Card.Header>Economic Webhook Data</Card.Header>
      <Card.Body>
        <pre dangerouslySetInnerHTML={{ __html: highlightedEconomicData }} />
        <CopyToClipboardButton textToCopy={jsonString} buttonText="Copy" successText="Copied" />
      </Card.Body>
    </Card>
  );
};

export default EconomicUserToken;
