import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { CustomToast } from '@src/components/toast';
// Types
import { InvoiceData, PaymentData } from './types';
// Components
import { PaginationComponent } from '@src/components/pagination';
// API
import { allInvoices, allPayments, getPaymentSetting, searchedInvoices, searchedPayments } from '@src/Api';
import { Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { InvoiceTab } from './invoiceTab';
import { PaymentTab } from './paymentTab';
import { Search } from '@src/components/search';

export const SyncOverviewPage: React.FC = () => {
  const [invoicesData, setInvoicesData] = useState<InvoiceData[]>([]);
  const [paymentData, setPaymentData] = useState<PaymentData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(25);
  const [resultsPerPage, setResultsPerPage] = useState<number>(25);
  const [toasts, setToasts] = useState<{ id: number; message: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSettingActivated, setPaymentSettingActivated] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState('invoices');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setActiveKey(key);
      setCurrentPage(0);
      setTotalResults(10);
      setInvoicesData([]);
      setPaymentData([]);

      if (paymentSettingActivated) {
        localStorage.setItem('syncActiveTab', key);
      }
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    fetchSyncData();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setClearSearch(true);
  };

  const fetchPaymentSetting = async () => {
    const paymentResponse = await getPaymentSetting();
    if (paymentResponse) {
      return paymentResponse.data;
    }
    return false;
  };

  const fetchSyncData = async () => {
    setLoading(true);

    if (clearSearch) {
      setSearchQuery('');
      setClearSearch(false);
    }

    if (activeKey === 'invoices') {
      let response;
      if (searchQuery) {
        response = await searchedInvoices(resultsPerPage, currentPage, searchQuery);
      } else {
        response = await allInvoices(resultsPerPage, currentPage);
      }

      if (response) {
        setInvoicesData(response.data.results);
        setTotalResults(response.data.total);
      }
    }

    if (activeKey === 'payments') {
      let response;
      if (searchQuery) {
        response = await searchedPayments(resultsPerPage, currentPage, searchQuery);
      } else {
        response = await allPayments(resultsPerPage, currentPage);
      }

      if (response) {
        setPaymentData(response.data.results);
        setTotalResults(response.data.total);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);

      const isPaymentSettingActivated = await fetchPaymentSetting();
      setPaymentSettingActivated(isPaymentSettingActivated);

      if (isPaymentSettingActivated) {
        const storedTab = localStorage.getItem('syncActiveTab');
        setActiveKey(storedTab || 'invoices');
      } else {
        setActiveKey('invoices');
      }

      fetchSyncData();
    };

    initializeData();
  }, [currentPage, resultsPerPage, activeKey, clearSearch]);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleDropdownChange = (value: number) => {
    setResultsPerPage(value);
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          {toasts.map((toast, index) => (
            <CustomToast
              duration={3000}
              key={index}
              show={true}
              title={`Sync status for ID: ${toast.id}`}
              message={toast.message}
              type="success"
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </AnimatePresence>
      </Container>
      <Row className="mt-4 sync-overview">
        <Col sm={12}>
          <Card>
            <Card.Header>Sync overview</Card.Header>
            <Card.Body>
              <Row className="my-4 p-2">
                <Search
                  searchQuery={searchQuery}
                  handleSearch={handleSearch}
                  handleInputChange={handleInputChange}
                  handleClearSearch={handleClearSearch}
                  placeholder="Search"
                ></Search>
              </Row>
              <Tabs className="custom-tab" activeKey={activeKey} id="sync-overview-tabs" onSelect={handleSelectTab}>
                <Tab eventKey="invoices" title="Invoices">
                  <InvoiceTab invoiceData={invoicesData} loading={loading} setToasts={setToasts} fetchSyncData={fetchSyncData} />
                </Tab>
                {paymentSettingActivated && (
                  <Tab eventKey="payments" title="Payments">
                    <PaymentTab paymentData={paymentData} loading={loading} setToasts={setToasts} fetchSyncData={fetchSyncData} />
                  </Tab>
                )}
              </Tabs>
            </Card.Body>
            <Row className="mt-4">
              <Col>
                <PaginationComponent
                  itemsCount={totalResults}
                  itemsPerPage={resultsPerPage}
                  currentPage={currentPage + 1}
                  setCurrentPage={(pageChangeTo) => setCurrentPage(pageChangeTo - 1)}
                />
              </Col>
              <Col className="result-dropdown">
                <select className="form-select dropdown" value={resultsPerPage} onChange={(e) => handleDropdownChange(parseInt(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
